<template>
  <div class="container">
    <h1>Notification</h1>
    <notifications></notifications>
  </div>
</template>

<script>
export default {
  components: {
    Notifications: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/components/common/Notifications.vue"
      ),
  },
};
</script>
